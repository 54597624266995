<template>
  <div>
    <b-overlay :show="showInfo" rounded="sm">
      <b-form
        align="left"
        id="formularioResiduos"
        @submit="modalEnviar"
        @reset="cancelarModal"
      >
        <b-card header="Datos Personales">
          <b-form-row>
            <b-col cols="12" lg="4">
              <div class="fotoReciclador">
                <img :src="verImagenReciclador" alt="foto reciclador" />
                <b-form-group>
                  <b-form-file
                    id="foto"
                    :state="Boolean(form.foto)"
                    placeholder="Foto Registro"
                    browseText="Buscar"
                    accept=".jpg, .JPEG, .JPG"
                    size="sm"
                    @change="imagenRegistro"
                    v-if="!cargadaFoto"
                    :disabled="verDetalles ? true : false"
                  ></b-form-file>
                </b-form-group>
              </div>
            </b-col>

            <b-col cols="12" lg="8">
              <b-form-row>
                <b-col cols="12" lg="4">
                  <b-form-group
                    id="group-nombre_1"
                    label="Primer nombre"
                    label-for="nombre_1"
                  >
                    <b-form-input
                      id="nombre_1"
                      v-model="form.nombre_1"
                      required
                      :disabled="verDetalles ? true : false"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="4">
                  <b-form-group
                    id="group-nombre_2"
                    label="Segundo nombre"
                    label-for="nombre_2"
                  >
                    <b-form-input
                      id="nombre_2"
                      v-model="form.nombre_2"
                      required
                      :disabled="verDetalles ? true : false"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="4">
                  <label for="fecha_cargue" class="d-block"
                    >Fecha registro:</label
                  >
                  <b-form-datepicker
                    id="fecha_cargue"
                    v-model="form.fecha_cargue"
                    class="mb-2 mr-2"
                    placeholder="YY/MM/DD"
                    locale="es"
                    labelNoDateSelected="No hay fecha"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    required
                    labelHelp="Use las flechas del teclado para navegar entre fechas"
                  >
                  </b-form-datepicker>
                </b-col>

                <b-col cols="12" lg="4">
                  <b-form-group
                    id="group-apellido_1"
                    label="Primer apellido"
                    label-for="apellido_1"
                  >
                    <b-form-input
                      id="apellido_1"
                      v-model="form.apellido_1"
                      required
                      :disabled="verDetalles ? true : false"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="4">
                  <b-form-group
                    id="group-apellido_2"
                    label="Segundo apellido"
                    label-for="apellido_2"
                  >
                    <b-form-input
                      id="apellido_2"
                      v-model="form.apellido_2"
                      required
                      :disabled="verDetalles ? true : false"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="4">
                  <b-form-row>
                    <b-col cols="12" lg="4">
                      <b-form-group
                        id="group-tipo_iden"
                        label="Tipo ID"
                        label-for="tipo_iden"
                      >
                        <b-form-select
                          id="tipo_iden"
                          v-model="form.tipo_iden"
                          class="mb-3"
                          required
                          :disabled="verDetalles ? true : false"
                        >
                          <b-form-select-option value="1"
                            >C.C</b-form-select-option
                          >
                          <b-form-select-option value="2"
                            >T.I</b-form-select-option
                          >
                          <b-form-select-option value="3"
                            >R.C</b-form-select-option
                          >
                          <b-form-select-option value="4"
                            >Pasaporte</b-form-select-option
                          >
                          <b-form-select-option value="5"
                            >C.E</b-form-select-option
                          >
                        </b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" lg="8">
                      <b-form-group
                        id="group-identificacion"
                        label="Num. ID"
                        label-for="identificacion"
                      >
                        <b-form-input
                          id="identificacion"
                          v-model="form.identificacion"
                          required
                          :disabled="verDetalles ? true : false"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                </b-col>

                <b-col cols="12" lg="4">
                  <b-form-row>
                    <b-col cols="12" lg="6">
                      <b-form-group
                        id="group-ciudad_expedicion"
                        label="Ciudad Exp."
                        label-for="ciudad_expedicion"
                      >
                        <b-form-input
                          id="ciudad_expedicion"
                          v-model="form.ciudad_expedicion"
                          list="listaCiudades"
                          @input="filtrarCiudadExpedicion"
                          @blur="seleccionarIdCiudadExpedicion"
                          required
                        ></b-form-input>

                        <datalist id="listaCiudades">
                          <option v-for="(ciudad, ciudadIndex) in ciudades" :key="ciudadIndex">{{ ciudad.nombre }}</option>
                        </datalist>

                      </b-form-group>
                    </b-col>

                    <b-col cols="12" lg="6">
                      <b-form-group
                        id="group-id_ciudad_nacim"
                        label="Ciudad Nacim."
                        label-for="id_ciudad_nacim"
                      >

                        <b-form-input
                          id="id_ciudad_nacim"
                          v-model="form.ciudad_nacim"
                          list="listaCiudadesNac"
                          @input="filtrarCiudadNacimiento"
                          @blur="seleccionarIdCiudadNacimiento"
                          required
                        ></b-form-input>

                        <datalist id="listaCiudadesNac">
                          <option v-for="(ciudad, ciudadIndex) in ciudadesNacimiento" :key="ciudadIndex">{{ ciudad.nombre }}</option>
                        </datalist>

                      </b-form-group>
                    </b-col>
                  </b-form-row>
                </b-col>

                <b-col cols="12" lg="4">
                  <b-form-row>
                    <b-col cols="12" lg="8">
                      <label for="fecha_nacim" class="d-block"
                        >Fecha nacim.:</label
                      >
                      <b-form-datepicker
                        id="fecha_nacim"
                        v-model="form.fecha_nacim"
                        class="mb-2 mr-2"
                        locale="es"
                        placeholder=""
                        labelNoDateSelected="No hay fecha"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        required
                        labelHelp="Use las flechas del teclado para navegar entre fechas"
                      >
                      </b-form-datepicker>
                    </b-col>

                    <b-col cols="12" lg="4">
                      <b-form-group
                        id="group-edad"
                        label="Edad"
                        label-for="edad"
                      >
                        <b-form-input
                          id="edad"
                          v-model="calcularEdad"
                          required
                          :disabled="verDetalles ? true : false"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                </b-col>

                <b-col cols="12" lg="4">
                  <b-form-row>
                    <b-col cols="12" lg="6">
                      <b-form-group
                        id="group-sexo"
                        label="Sexo"
                        label-for="sexo"
                      >
                        <b-form-select
                          id="sexo"
                          v-model="form.sexo"
                          class="mb-3"
                          required
                          :disabled="verDetalles ? true : false"
                        >
                          <b-form-select-option value="1"
                            >F</b-form-select-option
                          >
                          <b-form-select-option value="0"
                            >M</b-form-select-option
                          >
                        </b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" lg="6">
                      <b-form-group
                        id="group-pobla_rom"
                        label="Pobla. Rom"
                        label-for="pobla_rom"
                      >
                        <b-form-select
                          id="pobla_rom"
                          v-model="form.poblacion_rom"
                          class="mb-3"
                          :disabled="verDetalles ? true : false"
                        >
                          <b-form-select-option value="0">Ninguno</b-form-select-option>
                          <b-form-select-option value="1">Afrodescendiente</b-form-select-option>
                          <b-form-select-option value="2">Indigena</b-form-select-option>
                          <b-form-select-option value="3">Desplazado</b-form-select-option>
                          <b-form-select-option value="4">Migrante</b-form-select-option>
                          <b-form-select-option value="5">Gitano</b-form-select-option>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                </b-col>
              </b-form-row>
            </b-col>

            <b-col cols="12" md="6" lg="3" align-self="center">
              <b-form-group>
                  <b-form-checkbox v-model="form.cabeza_hogar">Es cabeza de hogar</b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="3">
              <b-form-group
                id="group-direccion"
                label="Dirección"
                label-for="direccion"
              >
                <b-form-input
                  id="direccion"
                  v-model="form.direccion"
                  required
                  :disabled="verDetalles ? true : false"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="3">
              <b-form-group
                id="group-id_ciudad_res"
                label="Ciudad residencia"
                label-for="id_ciudad_res"
              >
                <b-form-input
                  id="id_ciudad_res"
                  v-model="form.ciudad_res"
                  list="listaCiudadesRes"
                  @input="filtrarCiudadResidencia"
                  @blur="seleccionarIdCiudadResidencia"
                  required
                ></b-form-input>

                <datalist id="listaCiudadesRes">
                  <option v-for="(ciudad, ciudadIndex) in ciudadesResidencia" :key="ciudadIndex">{{ ciudad.nombre }}</option>
                </datalist>
               
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="3">
              <b-form-row>
                <b-col cols="12" md="6">
                  <b-form-group
                    id="group-estrato"
                    label="Estrato"
                    label-for="estrato"
                  >
                    <b-form-select
                      id="estrato"
                      v-model="form.estrato"
                      class="mb-3"
                      required
                      :disabled="verDetalles ? true : false"
                    >
                      <b-form-select-option value="1">1</b-form-select-option>
                      <b-form-select-option value="2">2</b-form-select-option>
                      <b-form-select-option value="3">3</b-form-select-option>
                      <b-form-select-option value="4">4</b-form-select-option>
                      <b-form-select-option value="5">5</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="6">
                  <b-form-group
                    id="group-tel_fijo"
                    label="Teléfono fijo"
                    label-for="tel_fijo"
                  >
                    <b-form-input
                      id="tel_fijo"
                      v-model="form.tel_fijo"
                      required
                      :disabled="verDetalles ? true : false"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-col>

            <b-col cols="12" md="6" lg="3">
              <b-form-group
                id="group-tel_celular"
                label="Teléfono celular"
                label-for="tel_celular"
              >
                <b-form-input
                  id="tel_celular"
                  v-model="form.tel_celular"
                  required
                  :disabled="verDetalles ? true : false"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="3" align-self="center">
              <b-form-row>
                <b-col cols="12" lg="6" align-self="center">
                  <b-form-checkbox v-model="form.lee">Lee</b-form-checkbox>
                </b-col>

                <b-col cols="12" lg="6" align-self="center">
                  <b-form-checkbox v-model="form.escribe">Escribe</b-form-checkbox>
                </b-col>
              </b-form-row>
            </b-col>

            <b-col cols="12" md="6" lg="2">
              <b-form-group
                id="group-nivel_educativo"
                label="nivel_educativo"
                label-for="nivel_educativo"
              >
                <b-form-select
                  id="nivel_educativo"
                  v-model="form.nivel_educativo"
                  class="mb-3"
                  required
                  :disabled="verDetalles ? true : false"
                >
                  <b-form-select-option value="0">Ninguno</b-form-select-option>
                  <b-form-select-option value="1"
                    >Primaria</b-form-select-option
                  >
                  <b-form-select-option value="2"
                    >Secundaria</b-form-select-option
                  >
                  <b-form-select-option value="3"
                    >Técnica</b-form-select-option
                  >
                  <b-form-select-option value="4"
                    >Universitario</b-form-select-option
                  >
                  <b-form-select-option value="5"
                    >Postgrado</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col cols="12" lg="4" align-self="center">
              <b-form-row>
                <b-col cols="12" md="5" lg="7" align-self="center">
                  <b-form-checkbox v-model="form.licencia_cond">
                    Licencia de <br />
                    conducción
                  </b-form-checkbox>
                </b-col>

                <b-col cols="12" md="7" lg="5">
                  <b-form-group
                    id="group-categoria"
                    label="Categoría"
                    label-for="categoria"
                  >
                    <b-form-input
                      id="categoria"
                      v-model="form.categoria"
                      :required="form.licencia_cond?true:false"
                      :disabled="verDetalles ? true : form.licencia_cond?false:true"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-col>

            <b-col cols="12" md="6" lg="3">
              <b-form-group
                id="group-tipo_vehiculo"
                label="Tipo Vehículo"
                label-for="tipo_vehiculo"
              >
                <b-form-input
                  id="tipo_vehiculo"
                  v-model="form.tipo_vehiculo"
                  :disabled="verDetalles ? true : false"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12" lg="3">
              <b-form-row>
                <b-col cols="12" lg="7" align-self="center">
                  <b-form-checkbox v-model="form.checkbox_seguridad_social">Seguridad <br> social</b-form-checkbox>
                </b-col>
                
                <b-col cols="12" lg="5">
                  <b-form-group
                    id="group-seguridad_social"
                    label="Seguridad Social"
                    label-for="seguridad_social"
                  >
                    <b-form-input
                      id="seguridad_social"
                      v-model="form.seguridad_social"
                      :disabled="verDetalles ? true : form.checkbox_seguridad_social?false:true"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-col>

            <b-col cols="12" lg="3">
              <b-form-row>
                <b-col cols="12" lg="6" align-self="center">
                  <b-form-checkbox v-model="form.checkbox_arl">ARL</b-form-checkbox>
                </b-col>
                
                <b-col cols="12" lg="6">
                  <b-form-group
                    id="group-arl"
                    label="ARL"
                    label-for="arl"
                  >
                    <b-form-input
                      id="arl"
                      v-model="form.arl"
                      :disabled="verDetalles ? true : form.checkbox_arl?false:true"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-col>

            <b-col cols="12" lg="3">
              <b-form-row>
                <b-col cols="12" lg="6" align-self="center">
                  <b-form-checkbox v-model="form.checkbox_pension">Pensión</b-form-checkbox>
                </b-col>
                
                <b-col cols="12" lg="6">
                  <b-form-group
                    id="group-pension"
                    label="Pensión"
                    label-for="pension"
                  >
                    <b-form-input
                      id="pension"
                      v-model="form.pension"
                      :disabled="verDetalles ? true : form.checkbox_pension?false:true"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-col>

            <b-col cols="12" md="6" lg="3">
              <b-form-group
                id="group-tipo_vivienda"
                label="Tipo de vivienda"
                label-for="tipo_vivienda"
              >
                <b-form-input
                  id="tipo_vivienda"
                  v-model="form.tipo_vivienda"
                  required
                  :disabled="verDetalles ? true : false"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="3">
              <b-form-group
                id="group-vivienda_es"
                label="Vivienda es"
                label-for="vivienda_es"
              >
                <b-form-input
                  id="vivienda_es"
                  v-model="form.vivienda_es"
                  required
                  :disabled="verDetalles ? true : false"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="3">
              <b-form-row>
                <b-col cols="12" lg="4">
                  <b-form-group
                    id="group-meses_actividad"
                    label="Meses act."
                    label-for="meses_actividad"
                  >
                    <b-form-input
                      id="meses_actividad"
                      v-model="form.meses_actividad"
                      required
                      :disabled="verDetalles ? true : false"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="8">
                  <b-form-group
                    id="group-actividad_anterior"
                    label="Actividad ant."
                    label-for="actividad_anterior"
                  >
                    <b-form-input
                      id="actividad_anterior"
                      v-model="form.actividad_anterior"
                      required
                      :disabled="verDetalles ? true : false"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-col>

            <b-col cols="12" md="6" lg="3">
              <b-form-row>
                <b-col cols="12" lg="4">
                  <b-form-group
                    id="group-dias_semana"
                    label="Dias de la semana"
                    label-for="dias_semana"
                  >
                    <b-form-select
                      id="dias_semana"
                      v-model="form.dias_semana"
                      class="mb-3"
                      required
                      :disabled="verDetalles ? true : false"
                    >
                      <b-form-select-option value="1">1</b-form-select-option>
                      <b-form-select-option value="2">2</b-form-select-option>
                      <b-form-select-option value="3">3</b-form-select-option>
                      <b-form-select-option value="4">4</b-form-select-option>
                      <b-form-select-option value="5">5</b-form-select-option>
                      <b-form-select-option value="6">6</b-form-select-option>
                      <b-form-select-option value="7">7</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="8">
                  <b-form-group
                    id="group-horas_semana"
                    label="Horas a la semana"
                    label-for="horas_semana"
                  >
                    <b-form-input
                      id="horas_semana"
                      v-model="form.horas_semana"
                      required
                      :disabled="verDetalles ? true : false"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-col>

            <b-col cols="12">
              <b-form-group
                id="group-actividad_alterna"
                label="Actividad alterna"
                label-for="actividad_alterna"
              >
                <b-form-input
                  id="actividad_alterna"
                  v-model="form.actividad_alterna"
                  required
                  :disabled="verDetalles ? true : false"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12" lg="4">
              <b-form-row>
                <b-col cols="12" md="6" align-self="center">
                  <b-form-group >
                     <b-form-checkbox v-model="form.rec_papel">Papel</b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="6" align-self="center">
                  <b-form-group>
                    <b-form-checkbox v-model="form.rec_carton">Cartón</b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="6" align-self="center">
                  <b-form-group>
                    <b-form-checkbox v-model="form.rec_plastico">Plástico</b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="6" align-self="center">
                  <b-form-group>
                    <b-form-checkbox v-model="form.rec_aluminio">Aluminio</b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="6" align-self="center">
                  <b-form-group>
                    <b-form-checkbox v-model="form.rec_vidrio">Vidrio</b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="6" align-self="center">
                  <b-form-group>
                    <b-form-checkbox v-model="form.rec_otro">Otro</b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    id="group-otro_cual"
                    label="Cual?"
                    label-for="otro_cual"
                  >
                    <b-form-input
                      id="otro_cual"
                      v-model="form.otro_cual"
                      :disabled="verDetalles ? true : false"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-col>

            <b-col cols="12" lg="8">
              <b-form-row>
                <b-col cols="12" lg="4">
                  <b-form-group
                    id="group-lugar_rec_material"
                    label="Lugar donde recicla"
                    label-for="lugar_rec_material"
                  >
                    <b-form-input
                      id="lugar_rec_material"
                      v-model="form.lugar_rec_material"
                      required
                      :disabled="verDetalles ? true : false"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="4">

                  <b-form-group
                    id="group-vehiculo_utilizado"
                    label="Vehículo utilizado"
                    label-for="vehiculo_utilizado"
                  >
                    <b-form-input
                      id="vehiculo_utilizado"
                      v-model="form.vehiculo_utilizado"
                      :disabled="verDetalles ? true : false"
                    ></b-form-input>
                  </b-form-group>

                </b-col>

                <b-col cols="12" lg="4">
                  <b-form-group
                    id="group-titularidad_vehiculo"
                    label="Vehículo propio"
                    label-for="titularidad_vehiculo"
                  >
                    <b-form-select
                      id="titularidad_vehiculo"
                      v-model="form.titularidad_vehiculo"
                      class="mb-3"
                      required
                      :disabled="verDetalles ? true : false"
                    >
                      <b-form-select-option value="1">Si</b-form-select-option>
                      <b-form-select-option value="0">No</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="4">
                  <b-form-group
                    id="group-sitio_venta"
                    label="Sitio venta"
                    label-for="sitio_venta"
                  >
                    <b-form-input
                      id="sitio_venta"
                      v-model="form.sitio_venta"
                      required
                      :disabled="verDetalles ? true : false"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="4">
                  <b-form-group
                    id="group-ubicacion"
                    label="Ubicación"
                    label-for="ubicacion"
                  >
                    <b-form-input
                      id="ubicacion"
                      v-model="form.ubicacion"
                      required
                      :disabled="verDetalles ? true : false"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="4">
                  <b-form-group
                    id="group-ingresos_semana"
                    label="Ingresos semana"
                    label-for="ingresos_semana"
                  >
                    <b-form-input
                      id="ingresos_semana"
                      v-model="form.ingresos_semana"
                      required
                      :disabled="verDetalles ? true : false"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-col>

           <b-col cols="12" md="4" v-if="eventosByAsistenteServicio.length > 0" class="text-left">
             <b-button type="button" @click="activarModal">
                Eventos Asistidos
              </b-button>
           </b-col> 

            <b-col cols="12" md>
              <div class="text-right">
                <b-button class="mr-3" v-if="editar" type="submit">
                  <b-icon icon="plus-circle"></b-icon>
                  Guardar
                </b-button>

                <b-button class="mr-3" v-if="!editar" type="submit">
                  <b-icon icon="plus-circle"></b-icon>
                  Guardar
                </b-button>

                <b-button type="reset"> Cancelar </b-button>
              </div>
            </b-col>

          </b-form-row>
        </b-card>
      </b-form>
    </b-overlay>

    <b-modal
      id="Modal"
      v-model="mostrarModalEventos"
      hide-footer
      size="xl"
    >

      <template #modal-title>Eventos asistidos</template>
      <div class="d-block text-center p-3">

        <b-row v-if="eventosByAsistenteServicio.length > 0">
          <b-col>
            <b-table
              id="asistenteEventos"
              striped
              responsive
              small
              bordered
              :items="eventosByAsistenteServicio"
              :fields="camposTablaEventos"
              :per-page="perPage"
              :current-page="currentPage"
              :tbody-tr-class="rowClass"
              select-mode="single"
            >
            </b-table>
          </b-col>
        </b-row>

        <b-row v-if="eventosByAsistenteServicio.length > 0">
          <b-col>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="consultaLicencias"
            ></b-pagination>
          </b-col>
        </b-row>

      </div>
    </b-modal>

  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import imagenDefault from "../../assets/imgCabeceras/fotoRecicladorDefault.png";
import moment from "moment";
import { encontrarError } from "../../contantesErrores";
export default {
  name: "detallesRecicladores",
  data() {
    return {
      mostrarModalEventos: false,
      verDetalles: false,
      cargadaFoto: null,
      showInfo: false,
      editar: false,
      ciudades: [],
      ciudadesNacimiento: [],
      ciudadesResidencia: [],
      form: {
        id: null,
        fecha_cargue: null,
        id_asociacion: null,
        foto: null,
        nombre_1: null,
        nombre_2: null,
        apellido_1: null,
        apellido_2: null,
        sexo: null,
        poblacion_rom: null,
        tipo_iden: null,
        identificacion: null,
        ciudad_expedicion: null,
        id_ciudad_expedicion: null,
        cabeza_hogar: false,
        ciudad_nacim: null,
        id_ciudad_nacim: null,
        fecha_nacim: null,
        edad: null,
        direccion: null,
        ciudad_res: null,
        id_ciudad_res: null,
        estrato: null,
        tel_fijo: null,
        tel_celular: null,
        lee: false,
        escribe: false,
        nivel_educativo: null,
        licencia_cond: false,
        categoria: null,
        tipo_vehiculo: null,
        checkbox_seguridad_social: false,
        seguridad_social: null,
        checkbox_pension: false,
        pension: null,
        checkbox_arl: false,
        arl: null,
        tipo_vivienda: null,
        vivienda_es: null,
        meses_actividad: null,
        actividad_anterior: null,
        dias_semana: null,
        horas_semana: null,
        actividad_alterna: null,
        lugar_rec_material: null,
        vehiculo_utilizado: null,
        titularidad_vehiculo: null,
        rec_papel: false,
        rec_carton: false,
        rec_plastico: false,
        rec_aluminio: false,
        rec_vidrio: false,
        rec_otro: false,
        otro_cual: null,
        sitio_venta: null,
        ubicacion: null,
        ingresos_semana: null,
      },
      camposTablaEventos: [
        {
          key: 'fecha_evento',
          formatter: (value) => {
            return moment(value).format("DD-MM-YYYY");
          },
        },
        "descripcion",
        {
          key: "num_acta",
          label: "Número Acta",
        },
      ],
      perPage: 10,
      currentPage: 1,
    };
  },
  async mounted() {
    console.log(this.recicladorSeleccionado)
    if (this.recicladorSeleccionado.id) {
      this.editar = true;
      try{
        await this.cargarRecicladorById(this.recicladorSeleccionado.id);
        await this.TraerEventosByAsistente(this.recicladorSeleccionado.id);
        console.log('registro cargado', this.recicladorCargado)
        this.mapearDatosCargados()
      } catch (e) {
        encontrarError(this, e.data);
      }
      
    }
    try {
      await this.cargarMunicipiosCompletos();
    } catch (e) {
      encontrarError(this, e.data);
    }
  },
  computed: {
    ...mapState("recicladores", [
      "recicladorSeleccionado",
      "municipiosServicios",
      "resultCreateReciclador",
      "recicladorCargado",
      "eventosByAsistenteServicio"
    ]),

    rows() {
      return this.eventosByAsistenteServicio.length;
    },

    verImagenReciclador() {
      let imagen = imagenDefault;
      if (this.form.foto) {
        imagen = `data:image/jpg;base64,${this.form.foto}`;
      }
      return imagen;
    },

    calcularEdad() {
      let edad = null;
      if (this.form.fecha_nacim) {
        let fechaNacimiento = moment(this.form.fecha_nacim);
        let fechaActual = moment();
        edad = fechaActual.diff(fechaNacimiento, "years");
      }
      return edad;
    },

    mapearCiudades() {
      let mapeadas = this.municipiosServicios.map((item) => {
        let ciudad = {
          value: item.id,
          text: item.nombre,
        };
        return ciudad;
      });
      let first = {
        value: null,
        text: "Seleccione una opción",
      };
      mapeadas.unshift(first);
      return mapeadas;
    },
  },
  methods: {
    ...mapActions(["activarLoading"]),
    ...mapActions("recicladores", [
        "cargarMunicipiosCompletos", 
        "createReciclador", 
        "editReciclador", 
        "cargarRecicladorById",
        "TraerEventosByAsistente"
      ]),

    rowClass(item, type) {
      if (!item || type !== "row") return;
      return "rowspace";
    },

    filtrarCiudadExpedicion(en){
      console.log('ciudad', en)
      if(this.form.ciudad_expedicion!=null && this.form.ciudad_expedicion!=''){
        let arrayFiltrado = this.municipiosServicios.filter(item=>{
          if(item.nombre.indexOf(this.form.ciudad_expedicion.toUpperCase().trim()) != -1){
            return item
          }
        })
        console.log('arrayFiltrado', arrayFiltrado)
        this.ciudades = arrayFiltrado
      }
    },

    seleccionarIdCiudadExpedicion(){
      this.form.id_ciudad_expedicion = this.ciudades[0].id
      this.ciudades=[]
    },

    filtrarCiudadNacimiento(en){
      console.log('ciudad', en)
      if(this.form.ciudad_nacim!=null && this.form.ciudad_nacim!=''){
        let arrayFiltrado = this.municipiosServicios.filter(item=>{
          if(item.nombre.indexOf(this.form.ciudad_nacim.toUpperCase().trim()) != -1){
            return item
          }
        })
        console.log('arrayFiltrado', arrayFiltrado)
        this.ciudadesNacimiento = arrayFiltrado
      }
    },

    seleccionarIdCiudadNacimiento(){
      this.form.id_ciudad_nacim = this.ciudadesNacimiento[0].id
      this.ciudades=[]
    },

    filtrarCiudadResidencia(en){
      console.log('ciudad', en)
      if(this.form.ciudad_res!=null && this.form.ciudad_res!=''){
        let arrayFiltrado = this.municipiosServicios.filter(item=>{
          if(item.nombre.indexOf(this.form.ciudad_res.toUpperCase().trim()) != -1){
            return item
          }
        })
        console.log('arrayFiltrado', arrayFiltrado)
        this.ciudadesResidencia = arrayFiltrado
      }
    },

    seleccionarIdCiudadResidencia(){
      this.form.id_ciudad_res = this.ciudadesResidencia[0].id
      this.ciudades=[]
    },

    traerNombreCiudadByID(id){
      let ciudad = null
      this.municipiosServicios.find(item=>{
        if(item.id==id){
          ciudad = item.nombre
          this.ciudades[item]
        }
      })
      console.log(ciudad)
      return ciudad
    },

    mapearDatosCargados(){
      let itemCargado = {...this.recicladorSeleccionado}
      itemCargado.foto = this.recicladorCargado.foto
      itemCargado.checkbox_seguridad_social = this.recicladorCargado.seguridad_social?true:false;
      itemCargado.checkbox_pension = this.recicladorCargado.pension?true:false;
      itemCargado.checkbox_arl = this.recicladorCargado.arl?true:false;
      itemCargado.cabeza_hogar = this.recicladorCargado.cabeza_hogar == 1?true:false;
      itemCargado.lee = this.recicladorCargado.lee == 1?true:false;
      itemCargado.escribe = this.recicladorCargado.escribe == 1?true:false;
      itemCargado.licencia_cond = this.recicladorCargado.licencia_cond == 1?true:false;
      itemCargado.rec_papel = this.recicladorCargado.rec_papel == 1?true:false;
      itemCargado.rec_carton = this.recicladorCargado.rec_carton == 1?true:false;
      itemCargado.rec_plastico = this.recicladorCargado.rec_plastico == 1?true:false;
      itemCargado.rec_aluminio = this.recicladorCargado.rec_aluminio == 1?true:false;
      itemCargado.rec_vidrio = this.recicladorCargado.rec_vidrio == 1?true:false;
      itemCargado.rec_otro = this.recicladorCargado.rec_otro == 1?true:false;
      itemCargado.ciudad_expedicion = this.traerNombreCiudadByID(this.recicladorCargado.id_ciudad_expedicion)
      itemCargado.ciudad_nacim = this.traerNombreCiudadByID(this.recicladorCargado.id_ciudad_nacim)
      itemCargado.ciudad_res = this.traerNombreCiudadByID(this.recicladorCargado.id_ciudad_res)

      this.form = itemCargado;
      console.log('formulario', this.form)
    },

    modalEnviar(event) {
      event.preventDefault();

      if (this.form.fecha_nacim != null) {
        this.$bvModal
          .msgBoxConfirm("Se van a guardar los datos, ¿Desea continuar?.", {
            title: "Por favor confirme",
            size: "md",
            buttonSize: "sm",
            okVariant: "primary",
            okTitle: "Continuar",
            cancelTitle: "Cancelar",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.enviarFormulario();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.modalMensaje("Por favor seleccione una fecha de nacimiento");
      }
    },

    async enviarFormulario() {
      this.activarLoading(true);
      let datosCrear = {
        registro: {...this.form},
      };
      
      datosCrear.registro.cabeza_hogar = this.form.cabeza_hogar?1:0
      datosCrear.registro.lee = this.form.lee?1:0
      datosCrear.registro.escribe = this.form.escribe?1:0
      datosCrear.registro.licencia_cond = this.form.licencia_cond?1:0
      datosCrear.registro.rec_papel = this.form.rec_papel?1:0
      datosCrear.registro.rec_carton = this.form.rec_carton?1:0
      datosCrear.registro.rec_plastico = this.form.rec_plastico?1:0
      datosCrear.registro.rec_aluminio = this.form.rec_aluminio?1:0
      datosCrear.registro.rec_vidrio = this.form.rec_vidrio?1:0
      datosCrear.registro.rec_otro = this.form.rec_otro?1:0

      console.log('datos a guardar', datosCrear)

      try {
        if (this.editar) {
          await this.editReciclador(datosCrear);
        } else {
          await this.createReciclador(datosCrear);
        }
        if (this.resultCreateReciclador.data.status == 200) {
          this.activarLoading(false);
          this.modalOk();
        }
      } catch (e) {
        this.activarLoading(false);
        encontrarError(this, e.data);
      }
    },

    modalOk() {
      this.$bvModal
        .msgBoxOk("Los Datos fueron guardados correctamente.", {
          title: "Confirmación",
          size: "md",
          buttonSize: "md",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then(async () => {
          console.log("creado");
          this.cancelarModal(true);
          
        })
        .catch((err) => {
          console.log(err);
        });
    },

    cancelarModal() {
      this.$emit("cancelarDetalles");
    },

    imagenRegistro(e) {
      let archivo = e ? e.target.files[0] : null;
      this.crearBase64(archivo);
    },

    crearBase64(file) {
      if (file) {
        let reader = new FileReader();
        reader.onload = (e) => {
          let archivo = e.target.result;
          let base = archivo.split("base64,");
          let pdf = base[1];
          this.form.foto = pdf;
        };
        reader.readAsDataURL(file);
      } else {
        this.form.foto = null;
      }
    },

    activarModal(){
      this.mostrarModalEventos = true
    }
  },
};
</script>

<style scoped>
.fotoReciclador {
  overflow: hidden;
  height: 285px;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 15px 15px 0 0;
}
.fotoReciclador img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 100%;
}
.fotoReciclador .form-group {
  position: absolute;
  bottom: 0;
  margin: 0;
}
</style>