<template>
  <div>
    <div class="cajaSuperior">
      <b-row>
        <b-col cols="12" md="3">
          <h2>
            criterios de <br />
            consulta
          </h2>
        </b-col>

        <!-- Formulario consulta -->
        <b-col>
          <div class="contenedorForm pl-5">
            <FormularioConsultaRecicladores @enviarFiltros="recibirFiltros"/>
          </div>
        </b-col>
      </b-row>
    </div>

    <br />
    <br />
    <b-row v-if="recicladoresServicios.length > 0">
      <b-col>
        <b-table
          id="consultaLicencias"
          striped
          responsive
          small
          bordered
          :items="recicladoresServicios"
          :fields="camposTabla"
          :per-page="perPage"
          :current-page="currentPage"
          :tbody-tr-class="rowClass"
          select-mode="single"
          ref="selectableTable"
          selectable
          @row-selected="onRowSelected"
        >
          <template #cell(seleccionar)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
      </b-col>
    </b-row>
    
    <b-row v-if="recicladoresServicios.length > 0">
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="consultaLicencias"
        ></b-pagination>
      </b-col>

      <!-- <b-col class="text-right">
        <b-button :to="{ name: 'agendarVisita' }" :disabled="validarButton">
          <b-icon icon="plus"></b-icon>
          Agregar Visita
        </b-button>
      </b-col> -->
    </b-row>

    <b-modal
      id="ModalAdicionarHistoria"
      v-model="mostrarModalDetalles"
      hide-footer
      size="xl"
      @hide="cancelarModalDetalles"
    >
      <template #modal-title>Detalles Recicladores</template>
      <div class="d-block text-center p-3">
        <DetallesRecicladores @cancelarDetalles="cancelarModalDetalles"/>
      </div>
    </b-modal>

  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
// import moment from "moment";
import FormularioConsultaRecicladores from "../../components/recicladores/FormularioConsultaRecicladores.vue";
import DetallesRecicladores from "../../components/recicladores/DetallesRecicladores.vue";
import { encontrarError } from '../../contantesErrores';
export default {
  name: "ConsultaCensoRecicladores",
  components: {
    FormularioConsultaRecicladores,
    DetallesRecicladores
  },
  data() {
    return {
      filtros: {},
      mostrarModalDetalles: false,
      camposTabla: [
        "seleccionar",
        "nombre_1",
        "nombre_2",
        "apellido_1",
        "apellido_2",
        {
          key: 'tipo_iden',
          formatter: 'mapearTipoDocumento'
        },
        "identificacion",
        "mun_expedicion",
        "nombre_corto",
      ],
      itemSeleccionado: [],
      perPage: 10,
      currentPage: 1,
    };
  },
  computed: {
    ...mapState("recicladores", [
      "recicladoresServicios",
      "recicladorSeleccionado",
    ]),
    rows() {
      return this.recicladoresServicios.length;
    },
    validarButton() {
      if (this.itemSeleccionado.length > 0) return false;
      return true;
    },
  },
  methods: {
    ...mapActions("recicladores", ["asignarRecicladorSeleccionado", "buscarRecicladores"]),
    rowClass(item, type) {
      if (!item || type !== "row") return;
      return "rowspace";
    },

    onRowSelected(items) {
      this.itemSeleccionado = items;
      if(items.length>0){
        let seleccionado={ ...items[0]}
        seleccionado.checkbox_seguridad_social = false
        seleccionado.checkbox_arl = false
        seleccionado.checkbox_pension = false
        this.asignarRecicladorSeleccionado(seleccionado);
        this.abrirModalDetalles()
      }else{
        this.asignarRecicladorSeleccionado({});
      }
    },

    abrirModalDetalles(){
      this.mostrarModalDetalles = true
    },

    cancelarModalDetalles() {
      this.mostrarModalDetalles=false
      this.buscarRegistros()
    },

    mapearTipoDocumento(id){
      if(Number(id) == 1){
        return 'CC'
      }
      if(Number(id) == 2){
        return 'TI'
      }
      if(Number(id) == 3){
        return 'RC'
      }
      if(Number(id) == 4){
        return 'Pasaporte'
      }
      if(Number(id) == 5){
        return 'CE'
      }
    },

    recibirFiltros(filtros){
      if(filtros){
        this.filtros = filtros
        this.buscarRegistros()
      }else{
        this.buscarRegistros()
      }
    },

    async buscarRegistros() {
      let datos={
        registroparams:this.filtros
      }
      try {
        await this.buscarRecicladores(datos);
      } catch (e) {
        encontrarError(this, e.data);
      }
    },
  },
};
</script>