<template>
  <b-form @submit.prevent="enviarForm">
    <b-form-row>
      <b-col cols="12" md="6">
        <label for="identificacion">Identificación</label>
        <b-form-input
          id="identificacion"
          class="mb-2 mr-sm-2 mb-sm-0"
          placeholder="Identificación"
          v-model="form.identificacion"
        ></b-form-input>
      </b-col>

      <b-col cols="12" md="6">
        <label for="apellido">Apellido</label>
        <b-form-input
          id="apellido"
          class="mb-2 mr-sm-2 mb-sm-0"
          placeholder="Apellido"
          v-model="form.apellido_1"
        ></b-form-input>
      </b-col>

      <b-col cols="12" md="6">
        <label for="id_asociacion">Asociación</label>
        <b-form-select
          id="id_asociacion"
          v-model="form.id_asociacion"
          :options="mapearAsociaciones"
          class="mb-3"
        >
        </b-form-select>
      </b-col>

      <b-col cols="12" md="6" align-self="end">
        <b-button
          class="vertical-align mb-3"
          variant="secondary"
          type="submit"
          block
        >
          <b-icon icon="search"></b-icon>
          Consultar
        </b-button>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { encontrarError } from "../../contantesErrores";

export default {
  name: "FormularioConsultaRecicladores",
  data() {
    return {
      form: {
        identificacion: null,
        apellido_1: null,
        id_asociacion: null,
      },
    };
  },
  async mounted(){
    try {
      await this.cargarAsociaciones();
    } catch (e) {
      encontrarError(this, e.data);
    }
  },
  computed: {
    ...mapState("recicladores", ["asociaciones"]),

    mapearAsociaciones() {
      let mapeadas = [];
      this.asociaciones.filter((item) => {
        let asoc = {
          value: item.id,
          text: item.nombre_corto,
        };
        mapeadas.push(asoc);
      });

      let first = {
        value: null,
        text: "Seleccione una opción",
      };
      mapeadas.unshift(first);
      return mapeadas;
    },
  },
  methods: {
    ...mapActions("recicladores", ["cargarAsociaciones", "buscarRecicladores"]),

    async enviarForm() {
      this.$emit('enviarFiltros', this.form)
    },
  },
};
</script>

<style scoped>
</style>